import React from 'react';
import ReactDOM from 'react-dom';
import {Comments} from "./Comments";
import {Products} from './Products';

ReactDOM.render(
    <React.StrictMode>
        <Products/>
    </React.StrictMode>,
    document.getElementById('products')
);

ReactDOM.render(
    <React.StrictMode>
        <Comments/>
    </React.StrictMode>,
    document.getElementById('comments')
);
