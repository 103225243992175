import React from "react";
import {Comment} from "./Comment";
import {loadScript} from "./script";

export class Comments extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            comments: []
        }
    }

    async componentWillMount() {
        await fetch("./comments.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({comments: data});

                loadScript();
            })
    }

    render() {
        return <div id="testimonial" className="section section-pad-sm bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <div className="comm-head">
                            <h1 className="heading-section">Что говорят наши клиенты</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="tes-slider owl-carousel has-carousel" data-items="2" data-loop="true"
                         data-dots="true">
                        {this.renderComments()}
                    </div>
                </div>
            </div>
        </div>
    }

    private renderComments() {
        return this.state.comments.map(x => <Comment data={x}/>)
    }
}