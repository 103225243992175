import React from 'react';

export class Products extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            products: []
        }
    }

    componentDidMount() {
        fetch("./products.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({products: data});
            })
    }

    render() {
        return <div id="products" className="section section-pad-sm product-sec bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 text-center">
                        <div className="comm-head">
                            <h1 className="heading-section">Невероятная цена и качество, которые вы никогда ещё не
                                видели!</h1>
                            <p>Выберите один из наших продуктов</p>
                        </div>
                    </div>
                </div>

                {this.renderProducts()}
            </div>
        </div>
    }

    renderProducts(): any {
        const result = [] as any;

        this.state.products.forEach((product: any) => {
            result.push(
                <div key={product.shopLink} className="row justify-content-center">
                    <div className="col-md-5 col-lg-5">
                        <div className="res-m-bttm text-center round">
                            <div className="product-img">
                                <img src={product.image} alt=""/>
                            </div>
                            <br/>
                            <h4>{product.description}</h4>
                            <h3>
                                <span className={""}
                                      style={{textDecoration: "line-through"}}>{product.priceFrom}</span>
                                <span className={""} style={{paddingLeft: "5px"}}> {product.priceTo}</span></h3>
                            <a href={product.shopLink} className="btn btn-outline">Заказать</a>
                        </div>
                    </div>
                </div>);

            result.push(<hr/>);
        })

        return result;
    }
}