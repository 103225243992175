import React from "react";

type Props = {
    data: any;
}

export class Comment extends React.Component<Props> {
    render() {
        const {name, city, comment} = this.props.data;

        return <div className="tes-item radious bg-dark light">
            <div className="profile-thumb d-flex align-items-start">
                <div className="profile">
                    <h5>{name}</h5>
                    <span>{city}</span>
                </div>
            </div>
            <p>{comment}</p>
            <div className="rating color-primary">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
        </div>
    }
}